
import Footer from '../components/Footer'
import Header from '../components/Header'
import { Helmet } from 'react-helmet'
import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';


class Home extends React.Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>methods - Website of webshop nodig?</title>
                </Helmet>
                <ScrollAnimation animateIn="zoomIn" animateOnce={true} duration={.1}>
                    <Header />
                    <Footer />
                </ScrollAnimation>
            </>
        );
    }
}

export default Home;
