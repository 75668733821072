import React, { useEffect } from "react";

import Routes from "./settings/routes";
import WebFont from "webfontloader";

import firebase from "firebase/app";
import { firebaseConfig } from "./settings/firebase";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import {IntlProvider} from 'react-intl'
const theme = createTheme({
    palette: {
     type: "light",
   }
});

export const App = () => {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Raleway:300,400,700,900", "sans-serif"],
      },
    });
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
  }, []);
  return (
    <>
      <ThemeProvider theme={theme}>
        <IntlProvider locale={navigator.language}>
        <CssBaseline />
        <Routes />
        </IntlProvider>
      </ThemeProvider>
    </>
  );
};
export default App;
